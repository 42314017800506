.App {
  text-align: center;
}

h1 {
  padding-bottom: 0;
  margin-bottom: 0;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #ffffff;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: rgb(51, 51, 51);
}

.App-link {
  color: #a0a0a0;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

.Terminal-window {
  box-shadow: none !important;
}

.Terminal-btn {
  display: none !important;
}

.terminal-wrapper {
  width: 500px;
  text-align: left;
}

@media only screen and (max-width: 600px) {
  .terminal-wrapper {
    width: 100%;
  }
}